import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../environments/environment';
import { FormControl } from '@angular/forms';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VacanciesService {
  private baseUrl = environment.baseUrl;
  headers = new HttpHeaders({ 'Authorization': 'allow' });
  constructor(private http: HttpClient) { }

  getAllVacancies(): Promise<any> {
    return this.http.get<any>(`${this.baseUrl}vacancies`, { headers: this.headers }).toPromise();
  }

  createVacancy(job: any): Promise<any> {
    return this.http.post<any>(`${this.baseUrl}vacancies`, job, { headers: this.headers }).toPromise();
  }

  updateJob(id, job) {
    return this.http.put<any>(`${this.baseUrl}vacancies/${id}`, job, { headers: this.headers }).toPromise();
  }

  deleteJob(id) {
    return this.http.delete<any>(`${this.baseUrl}vacancies/${id}`, { headers: this.headers }).toPromise();
  }

  getPresignedPost(): Promise<any> {
    return this.http.post<any>(`${this.baseUrl}vacancyPostSigner`, { fileExtension: ".html", folder: "vacancy" }, { headers: this.headers }).toPromise();
  }

  uploadArticleFile(url: string, formdata: any): Promise<any> {
    let header = new HttpHeaders({ 'enctype': 'multipart/form-data' });
    return this.http.post(url, formdata, { headers: header }).toPromise();
  }
  getUploadedVacancyDesc(key: string): Promise<any> {
    return this.http.get(`${this.baseUrl}getVacancyFile`, {
      headers: this.headers,
      params: new HttpParams().set('key', key)
    }).toPromise();
  }

  getHtmlVacancies(keys: any[]): Promise<any> {
    let query = [];
    keys.forEach(key => {
      query.push(
        this.http.get(`${this.baseUrl}getVacancyFile`, { headers: this.headers, params: new HttpParams().set('key', key) })
      );
    });
    return forkJoin(query).toPromise();
  }

  nameValidator(control: FormControl): { [s: string]: boolean } {
    const nameRegex = /^[a-zA-Z]+(([' -][a-zA-Z ])?[a-zA-Z]*)*$/;

    if (!nameRegex.test(control.value) && control.value) {
      return { nameValidator: true };
    }
    return null;
  }
}
