import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MediaCoverageService {

  private baseUrl = environment.baseUrl;
  headers = new HttpHeaders({ 'Authorization': 'allow' });
  constructor(private http: HttpClient) { }


  getAllMediaCoverages(): Promise<any> {
  
    return this.http.get<any>(`${this.baseUrl}mediaCoverages`, { headers: this.headers }).toPromise();
  }

  createMediaCoverages(coverage) {
    let header = new HttpHeaders({ 'Authorization': 'allow' });
    return this.http.post(`${this.baseUrl}mediaCoverages`, coverage, { headers: header }).toPromise();
  }

  updateMediaCoverages(id,coverage){
    return this.http.put<any>(`${this.baseUrl}mediaCoverages/${id}`, coverage, { headers: this.headers}).toPromise();
  }

  deleteCoverage(id){
    return this.http.delete<any>(`${this.baseUrl}mediaCoverages/${id}`, { headers: this.headers }).toPromise();
  }

}
