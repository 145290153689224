import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MediaService } from '../../../services/media/media.service'

@Component({
  selector: 'app-media-edit',
  templateUrl: './media-edit.component.html',
  styleUrls: ['./media-edit.component.scss']
})
export class MediaEditComponent implements OnInit {
  MediaForm: FormGroup;
  filename: any;
  selectedfile: any;
  fileExtension: string;
  formData = new FormData();
  fileformatMessage = null;
  mode = "Add";
  loader;
  mediarelease = null;;
  UploadedFileName;

  fileChanged = false;

  constructor(private _snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data: MatDialog, private formBldr: FormBuilder, private mediaService: MediaService) { }

  ngOnInit() {

    this.mediarelease = this.data;
    this.MediaForm = this.formBldr.group({
      title: [
        null, [
          Validators.required,
          Validators.maxLength(255),
          Validators.minLength(2),

        ]
      ],
      description: [
        null, [
          Validators.required,
          Validators.maxLength(255),
          Validators.minLength(2),

        ]
      ]
    });

    if (this.mediarelease != null) {

      this.mode = "Edit";
      this.MediaForm.setValue({
        title: this.mediarelease.title,
        description: this.mediarelease.description,
      });

      this.filename = this.mediarelease.Mediafile.fileName + '.' + this.mediarelease.Mediafile.extension;

    }
    console.log(this.MediaForm.status);


  }

  OnFileSelected(event) {

    this.selectedfile = event.target.files[0];
    this.filename = this.selectedfile.name;

    this.filename = this.filename.split('.');

    this.fileExtension = this.filename[this.filename.length - 1];
    this.UploadedFileName = this.filename[0];

    if (this.fileExtension == 'pdf' || this.fileExtension == 'docx') {
      this.fileformatMessage = null;
      this.fileChanged = true;
    } else {
      this.openSnackBar("You can only upload PDF or DOCX files");
      this.fileformatMessage = "You can only upload PDF or DOCX files"
    }



  }

  getPresignedUrl(): Promise<any> {

    this.loader = "SENDING";

    let dataObject;

    return new Promise((resolve, reject) => {

      this.mediaService.getpresignedurls(this.fileExtension).then(res => {
        dataObject = res

        let fileKey = dataObject.id;

        Object.keys(dataObject.presignedPost.fields).forEach(key => this.formData.append(key, dataObject.presignedPost.fields[key]));
        this.formData.append('file', this.selectedfile);

        this.mediaService.uploadFile(dataObject.presignedPost.url, this.formData).then(
          data => {
            let res = data

            if (fileKey != null) {
              let mediaReleaseObject = {
                Mediafile: {
                  key: fileKey,
                  fileName: this.UploadedFileName,
                  extension: this.fileExtension
                }
              }
              resolve(mediaReleaseObject);
            }

          });

      })


    });



  }

  openSnackBar(message: string) {
    this._snackBar.open(message, "Ok", {
      duration: 4500,
      verticalPosition: 'top'
    });
  }

  submitMediaRealese() {
    this.loader = "SENDING";
    let mediaRelease;

    if (this.mediarelease != null) {
      //if file changed re-upload to s3 and update objects, else update fields only
      if (this.fileChanged) {
        this.getPresignedUrl().then(
          resp => {
           
            mediaRelease = {
              title: this.MediaForm.value.title,
              description: this.MediaForm.value.description,
              ...resp
            }
            this.updateMediaRelease(mediaRelease);

          }
        ).catch(
          error => {
            //console.log(error);

          }
        )
      } else {

        mediaRelease = {
          title: this.MediaForm.value.title,
          description: this.MediaForm.value.description,
          Mediafile: this.mediarelease.Mediafile
        }

        this.updateMediaRelease(mediaRelease);

      }



    } else {
      this.getPresignedUrl().then(
        resp => {

          mediaRelease = {
            title: this.MediaForm.value.title,
            description: this.MediaForm.value.description,
            ...resp
          }
          this.createMediaRelease(mediaRelease)

        }
      ).catch(
        error => {
          //console.log(error);

        }
      );

    }

  }

  createMediaRelease(mediaRelease) {
    this.mediaService.createMediaRelease(mediaRelease).then(
      resp => {
        console.log(resp);
        this.loader = "DONE";
      }
    ).catch(
      error => {
        //console.log(error);

      }
    )
  }

  updateMediaRelease(mediaRelease) {
    this.mediaService.updateMediaRelease(this.mediarelease.id, mediaRelease).then(
      resp => {
        console.log(resp);
        this.loader = "DONE";
      }
    ).catch(
      error => {
        //console.log(error);

      }
    );
  }




}
