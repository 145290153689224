import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class MilestonesService {

  private baseUrl =  environment.baseUrl;
  headers = new HttpHeaders({ 'Authorization': 'allow' });
  constructor(private http: HttpClient) { }


  getAllMilestones(): Promise<any> {
  
    return this.http.get<any>(`${this.baseUrl}milestones`, { headers: this.headers }).toPromise();
  }

  createMilestone(milestone){
    return this.http.post<any>(`${this.baseUrl}milestones`, milestone, { headers: this.headers}).toPromise();
  }

  updateMilestone(id,milestone){
    return this.http.put<any>(`${this.baseUrl}milestones/${id}`, milestone, { headers: this.headers}).toPromise();
  }

  deleteMilestone(id){
    return this.http.delete<any>(`${this.baseUrl}milestones/${id}`, { headers: this.headers}).toPromise();
  }


  nameValidator(control: FormControl): { [s: string]: boolean } {
    const nameRegex = /^[a-zA-Z]+(([' -][a-zA-Z ])?[a-zA-Z]*)*$/;

    if (!nameRegex.test(control.value) && control.value) {
      return { nameValidator: true };
    }
    return null;
  }
}
