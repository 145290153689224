import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaService } from 'src/app/services/media/media.service';


export interface Media {
  Mediafile: {
    key: null
  }
};

@Component({
  selector: 'app-releases',
  templateUrl: './releases.component.html',
  styleUrls: ['./releases.component.scss']
})
export class ReleasesComponent implements OnInit {
  fileObj;
  loading = true;

  mediaReleaseArr

  constructor(
    private activatedRoute: ActivatedRoute,
    private _sanitizationService: DomSanitizer,
    private mediaService: MediaService,
    private router: Router
  ) {



    let id;
    this.activatedRoute.params.subscribe(
      resp => {
        id = resp
     //   console.log(resp)
      }
    );
    let key;

    if (history.state.data) {
      let media: Media = history.state.data;
   //   console.log(media);
      
      key = media['Mediafile']['key'];
      this.getFile(key)
    } else if (id) {

      this.mediaService.getAllMediaRelease().then(media => {
        this.mediaReleaseArr = media['mediaRelease'].reverse();
        for (let index = 0; index < this.mediaReleaseArr.length; index++) {
          const element = this.mediaReleaseArr[index];
          if (element.title === id.id) {
            key = element['Mediafile']['key'];
            this.getFile(key)
            return;
          }
        }

        if(!key){
          router.navigate(['/'])
        }

      });

    } else {
      router.navigate(['/'])
    }


//
 //   console.log(key);


  
  }

  getFile(key){
    this.mediaService.getUploadedFile(key).then(
      resp => {

        let base = 'data:application/pdf;base64,' + resp;
        this.fileObj = this._sanitizationService.bypassSecurityTrustResourceUrl(base);
        this.loading = false;
      }
    ).catch(
      error => {
     //   console.log(error);

      });
  }

  ngOnInit() {
    window.scrollTo({
      top: 0,
    });
  }

}
