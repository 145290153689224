import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  private baseUrl = environment.blogUrl;
  private orgId = environment.organizationId;
  private apiKey = environment.apiKey;
  private headers = new HttpHeaders({ 'Authorization': this.apiKey});

  constructor(private http: HttpClient) { 
  }

  getBlogsListByOrgId() : Promise <any>{
    return this.http.get<any>(`${this.baseUrl}blog`, {
      headers: this.headers,
      params: new HttpParams().set('getArticleBody', "true").set('organizationId', this.orgId)
    }).toPromise();
  }

  getArticleBody(idArr): Promise <any>{
    let query = [];
    idArr.forEach(element => {
        query.push(
          this.http.get<any>(`${this.baseUrl}blog/${element}`, {
            headers: this.headers
          }).toPromise()
        )
    }); 
    return forkJoin(query).toPromise();
  }
}
