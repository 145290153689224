import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { promise } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private baseUrl =  environment.baseUrl;
  constructor(private http : HttpClient) { }
  headers = new HttpHeaders({ 'Authorization': 'allow'});

  sendEmail(email): Promise<any>
  {
   
    return this.http.post<any>(`${this.baseUrl}contactUsEmail`, email, {headers : this.headers}).toPromise();
  }
}
