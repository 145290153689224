import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MilestonesService } from '../../../services/milestones/milestones.service'
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-milestones-edit',
  templateUrl: './milestones-edit.component.html',
  styleUrls: ['./milestones-edit.component.scss']
})
export class MilestonesEditComponent implements OnInit {

  milestoneDetailsForm: FormGroup;
  milestone;
  mode = "Add";
  loader;
  constructor(private formBldr: FormBuilder, private milestoneService: MilestonesService, @Inject(MAT_DIALOG_DATA) public data: MatDialog) { }

  ngOnInit() {

    this.milestone = this.data;
    this.milestoneDetailsForm = this.formBldr.group({
      dateOfAchievement: [
        null, [
          Validators.required,
          Validators.maxLength(10)

        ]
      ],
      description: [
        null, [
          Validators.required,
          Validators.maxLength(255),
          Validators.minLength(2),
          // this.milestoneService.nameValidator.bind(this),
        ]
      ]
    });

    if (this.milestone != null) {
      console.log(this.milestone);

      //let dateSplit = this.milestone.dateOfAchivement.split("/");
      this.mode = "Edit";
      this.milestoneDetailsForm.setValue({
        dateOfAchievement: this.milestone.dateOfAchivement,
        description: this.milestone.milestone,
      });
    }
  }


  submitMilestone() {
    this.loader = "SENDING";
    let milestone = {
      milestone: this.milestoneDetailsForm.value.description,
      dateOfAchivement: this.milestoneDetailsForm.value.dateOfAchievement
    }

    if (this.milestone != null) {
      let id = this.milestone.id
      this.milestoneService.updateMilestone(id, milestone).then(resp => {
        console.log(resp)
        this.loader = "DONE"
      }).catch(error => {
        console.log(error)
      });

      return;
    }

    console.log(milestone);

    if (this.milestoneDetailsForm.valid != null) {
      this.milestoneService.createMilestone(milestone).then(resp => {
        console.log(resp)
        this.loader = "DONE"
      }).catch(error => {
        console.log(error)
      });
    }
  }


}
