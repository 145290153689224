import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { LandingComponent } from './components/landing/landing.component';
import { MatIconModule } from '@angular/material/icon';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AboutComponent } from './components/about/about.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { MilestonesComponent } from './components/dashboard/milestones/milestones.component';
import { FaqsComponent } from './components/dashboard/faqs/faqs.component';
import { MediaComponent } from './components/dashboard/media/media.component';
import { StatsComponent } from './components/dashboard/stats/stats.component';
import { StatsEditComponent } from './components/modals/stats-edit/stats-edit.component';
import { VacanciesEditComponent } from './components/modals/vacancies-edit/vacancies-edit.component';
import { MilestonesEditComponent } from './components/modals/milestones-edit/milestones-edit.component';
import { FaqsEditComponent } from './components/modals/faqs-edit/faqs-edit.component';
import { VacanciesComponent } from './components/dashboard/vacancies/vacancies.component';
import { MediaEditComponent } from './components/modals/media-edit/media-edit.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule, MatTableModule, MatStepperModule } from '@angular/material';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatRippleModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TiceComponent } from './components/about/tice/tice.component';
import { TutehPropComponent } from './components/about/tuteh-prop/tuteh-prop.component';
import { YabathuComponent } from './components/about/yabathu/yabathu.component';
import { LegacyComponent } from './components/about/legacy/legacy.component';
import { ConfirmationComponent } from "./components/modals/confirmation/confirmation.component";
import { TimeAgoPipe } from 'time-ago-pipe';
import * as firebase from 'firebase/app';
import { CareersComponent } from './components/careers/careers.component';
import { MediaCoverageComponent } from './components/dashboard/media-coverage/media-coverage.component';
import { MediaCoverageEditComponent } from './components/modals/media-coverage-edit/media-coverage-edit.component';
import { QuillModule } from 'ngx-quill';
import { ViewVacancyDescComponent } from './components/modals/view-vacancy-desc/view-vacancy-desc.component';
import { ReleasesComponent } from './components/releases/releases.component';
import { BlogComponent } from './components/blog/blog.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
var firebaseConfig = {
  apiKey: "AIzaSyDOeSU8b9Ne_DIDedyFP7Fxr41qMAnuokM",
  authDomain: "tuteh-a6046.firebaseapp.com",
  databaseURL: "https://tuteh-a6046.firebaseio.com",
  projectId: "tuteh-a6046",
  storageBucket: "tuteh-a6046.appspot.com",
  messagingSenderId: "255580919371",
  appId: "1:255580919371:web:d7cff82043a68774405fce"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  autoplay: {
    delay: 14000,
  },
  direction: 'horizontal',
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    dynamicBullets: true,
    clickable: true,
  },
  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    AboutComponent,
    DashboardComponent,
    LoginComponent,
    MilestonesComponent,
    FaqsComponent,
    MediaComponent,
    StatsComponent,
    StatsEditComponent,
    VacanciesEditComponent,
    MilestonesEditComponent,
    FaqsEditComponent,
    VacanciesComponent,
    MediaEditComponent,
    TiceComponent,
    TutehPropComponent,
    YabathuComponent,
    LegacyComponent,
    TimeAgoPipe,
    ConfirmationComponent,
    CareersComponent,
    MediaCoverageComponent,
    MediaCoverageEditComponent,
    ViewVacancyDescComponent,
    ReleasesComponent,
    BlogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    SwiperModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    MatFormFieldModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatTabsModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSidenavModule,
    MatExpansionModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSortModule,
    MatProgressBarModule,
    MatIconModule,
    MatToolbarModule,
    MatRippleModule,
    MatListModule,
    MatChipsModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatMenuModule,
    QuillModule.forRoot(
      {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            //['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            //[{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction

            //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [3, 4, 5, false] }],

            //[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            //[{ 'font': [] }],
            [{ 'align': [] }],

            ['clean'],                                         // remove formatting button

            ['link']                         // link and image, video
          ]
        }
      }
    )

  ],
  entryComponents: [
    StatsEditComponent,
    VacanciesEditComponent,
    MilestonesEditComponent,
    FaqsEditComponent,
    MediaEditComponent,
    ConfirmationComponent,
    MediaCoverageEditComponent,
    ViewVacancyDescComponent,
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
