// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: "https://dev.api.tutenterprise.co.za/",
  blogUrl: "https://dev.api.blog.t-res.co.za/",
  organizationId : 'f30550bf-49fb-4444-bd1d-715c3c7276df',
  userId : '4c6da148-5545-4ac9-a530-bb7f8588d4dd',
  name: "DEV",
  apiKey: 'a8f64661-f4af-4df7-a6ea-26e72b640624',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
