import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {

  private baseUrl = environment.baseUrl;
  private headers = new HttpHeaders({ 'Authorization': 'allow' });
  private fileHeaders = new HttpHeaders({ 'enctype': 'multipart/form-data' });
  private orgId = environment.organizationId
  constructor(private http: HttpClient) { }

  getPresignedPost(): Promise<any> {
    return this.http.post<any>(`${this.baseUrl}blog/files`, null, { headers: this.headers }).toPromise();
  }

  uploadArticleFile(presignedUrl, form): Promise<any> {
    return this.http.post<any>(presignedUrl, form, { headers: this.fileHeaders }).toPromise();
  }

  createBlogPost(blogPost: any): Promise<any> {
    return this.http.post<any>(`${this.baseUrl}blog`, blogPost, { headers: this.headers }).toPromise();
  }

  getBlogsListByOrgId(): Promise<any> {
   
    return this.http.get<any>(`${this.baseUrl}blog`, {
      headers: this.headers,
      params: new HttpParams().set('getArticleBody', "false").set('organizationId', this.orgId)
    }).toPromise();
  }

  getBlogsList(): Promise<any> {
   
    return this.http.get<any>(`${this.baseUrl}blog`, {
      headers: this.headers,
      params: new HttpParams().set('getArticleBody', "false")
    }).toPromise();
  }

}
