import { Injectable } from '@angular/core';
import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private user;
  public isAuthicated: BehaviorSubject<any>;
  constructor() {
    this.isAuthicated = new BehaviorSubject(false);
  }

  getAuthState(): Observable<any> {
    return this.isAuthicated.asObservable();
  }

  authState() {

    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {

          if (user['email'] == "itadmin@tutenterprise.co.za") {
            this.isAuthicated.next(true);
            resolve(user['email']);
          } else {
            this.isAuthicated.next(false);
            reject(null);
          }

        } else {
          this.isAuthicated.next(false);
          reject(null);
        }
      });
    })
  }

  userSignin(email, password) {

    return new Promise((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(email, password).then(
        resp => {
          this.isAuthicated.next(true);
          resolve(resp);
        }
      ).catch(error => {
        this.isAuthicated.next(false);
        reject(error);

      });
    });


  }

  signout() {

    return new Promise((resolve, reject) => {
      firebase.auth().signOut().then(resp => {
        this.isAuthicated.next(false);
        resolve(resp);
      }).catch(error => {
        reject(error)
      });
    });

  }
}
