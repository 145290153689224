import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { StatsService } from '../../../services/stats/stats.service'
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-stats-edit',
  templateUrl: './stats-edit.component.html',
  styleUrls: ['./stats-edit.component.scss']
})
export class StatsEditComponent implements OnInit {
  stat;
  StatsDetailsForm: FormGroup
  loader
  mode = "Add";
  constructor(@Inject(MAT_DIALOG_DATA) public data: MatDialog, private formBldr: FormBuilder, private statsService: StatsService) { }

  ngOnInit() {
    this.stat = this.data;
    this.StatsDetailsForm = this.formBldr.group({

      statName: [
        null, [
          Validators.required,
          Validators.maxLength(255),
          Validators.minLength(1),
        ]
      ],
      statValue: [
        null, [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
        ]
      ],
    });

    if (this.stat != null) {
      this.mode = "Edit";
      this.StatsDetailsForm.setValue({
        statName: this.stat.statName,
        statValue: this.stat.statValue,
      });
    }
  }

  submitStats() {
    this.loader = "SENDING";

    //change to "DONE" inside the promise
    let stat = {
      statName: this.StatsDetailsForm.value.statName,
      statValue: this.StatsDetailsForm.value.statValue,
    };
    console.log(stat);

    if (this.stat != null) {
      let id = this.stat.id
      this.statsService.updateStat(id, stat).then(resp => {
        this.loader = "DONE";
      }).catch(error => {
        console.log(error)
      });

      return;
    }else{
      this.statsService.createStat(stat).then(resp => {
        this.loader = "DONE"
      }).catch(error => {
        console.log(error)
      });
    }
  }
}
