import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { MediaEditComponent } from '../../modals/media-edit/media-edit.component';
import { MediaService } from 'src/app/services/media/media.service';
import { ConfirmationComponent } from '../../modals/confirmation/confirmation.component';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {
  displayedColumns: string[] = ['tittle', 'description','actions'];
  dataSource;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  mediaReleaseArr: any;
 
  constructor(private dialog: MatDialog, private mediaService: MediaService) { }

  ngOnInit() {
    this.getAllMedia();
  }

  add(){
    this.dialog.open(MediaEditComponent, { autoFocus: false,}).afterClosed().subscribe(resp => {
      this.getAllMedia();
    });
  }

  edit(element){
    this.dialog.open(MediaEditComponent, { autoFocus: false, data : element}).afterClosed().subscribe(resp => {
      this.getAllMedia();
    });
  }


  delete(id){
    let msg = "Are you sure you want to delete this Media Release";
    console.log(id);
    
    this.dialog.open(ConfirmationComponent, {data : msg }).afterClosed().subscribe(
      resp => {
        if(resp) {
          this.dataSource = null;
          this.mediaService.deleteMediaRelease(id).then(
           resp => {
            this.getAllMedia()
           }
          ).catch(error => {
            console.log(error)
            this.getAllMedia()
          }
          );
        }
      }
    )
  }

  getAllMedia(){
    this.dataSource = null;

    this.mediaService.getAllMediaRelease().then(data => {
      this.mediaReleaseArr = data,
      this.mediaReleaseArr = this.mediaReleaseArr.mediaRelease,
      this.dataSource = new MatTableDataSource<any>(this.mediaReleaseArr);
      this.dataSource.paginator = this.paginator;
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
