import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class StatsService {

  
  private baseUrl = environment.baseUrl;
  headers = new HttpHeaders({ 'Authorization': 'allow' });
  constructor(private http: HttpClient) { }

  getAllStatistics(): Promise<any> {
  
    return this.http.get<any>(`${this.baseUrl}stats`, { headers: this.headers }).toPromise();
  }

  createStat(stat) {
    let header = new HttpHeaders({ 'Authorization': 'allow' });
    return this.http.post(`${this.baseUrl}stats`, stat, { headers: header }).toPromise();
  }

  updateStat(id,stat){
    return this.http.put<any>(`${this.baseUrl}stats/${id}`, stat, { headers: this.headers}).toPromise();
  }

  deleteStat(id){
    return this.http.delete<any>(`${this.baseUrl}stats/${id}`, { headers: this.headers }).toPromise();
  }

}
