import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loader = false;
  signInEmail = null;
  signInPassword = null;

  constructor(private dialog: MatDialog, private _snackBar: MatSnackBar, private router: Router, private authSrvc: AuthService) {

  }

  ngOnInit() {
  }

  userSignin() {

    if (this.signInEmail && this.signInPassword) {
      this.loader = true

      this.authSrvc.userSignin(this.signInEmail, this.signInPassword).then(
        user => {
          user = user['user'];
          console.log(user);

          let email = user['email'];


          this.router.navigate(['/admin']);
          this.loader = false

        }
      ).catch(error => {
        this.loader = false
        this.authValidator(error);

      });

    } else {
      this.myAlert("please provide your login details")
    }
  }


  myAlert(msg) {
    this._snackBar.open(msg, "ok", {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
    });
  }
  authValidator(error) {
    switch (error['code']) {
      case "auth/wrong-password":
        this.myAlert("Your email or password is invalid");
        break;
      case "auth/invalid-email":
        this.myAlert("Please enter a valid email");
        break;
      case "auth/user-not-found":
        this.myAlert("You dont have an account yet");
        break;
      case "auth/weak-password":
        this.myAlert("Password should be at least 6 characters");
        break;
      case "auth/email-already-in-use":
        this.myAlert("The email address is already in use by another account.");
        break;
      default:
        this.myAlert("Something went wrong, please try again later");

    }
  }
  

}
