import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { VacanciesEditComponent } from '../../modals/vacancies-edit/vacancies-edit.component';
import { VacanciesService } from '../../../services/vacancies/vacancies.service'
import { ConfirmationComponent } from '../../modals/confirmation/confirmation.component';
import { ViewVacancyDescComponent } from '../../modals/view-vacancy-desc/view-vacancy-desc.component';

@Component({
  selector: 'app-vacancies',
  templateUrl: './vacancies.component.html',
  styleUrls: ['./vacancies.component.scss']
})
export class VacanciesComponent implements OnInit {

  displayedColumns: string[] = ['position', 'closingDate','Status', 'actions'];
  dataSource;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private dialog: MatDialog, private vacancyService: VacanciesService) { }

  ngOnInit() {
    this.getAllVacancies();
  }

  add() {
    this.dialog.open(VacanciesEditComponent, { autoFocus: false, }).afterClosed().subscribe(resp => {
      this.getAllVacancies();
    });

  }
  edit(element) {
    let file = element.descriptionFile;
    this.vacancyService.getUploadedVacancyDesc(file.key).then(resp => {
      element['vacancyDesc'] = window.atob(resp);
    this.dialog.open(VacanciesEditComponent, { autoFocus: false, data: element }).afterClosed().subscribe(resp => {
      this.getAllVacancies();
    });
  });
    console.log(element.id);
  }
  // viewDescrition(element) {
  //   this.dialog.open(ViewVacancyDescComponent, { autoFocus: false, data: element })
  //   console.log(element.id);

  // }

  delete(id) {
    let msg = "Are you sure you want to delete this Vacancy";
    console.log(id);
    this.dialog.open(ConfirmationComponent, { data: msg }).afterClosed().subscribe(
      resp => {
        if (resp) {
          console.log(id);
          this.dataSource = null;
          this.vacancyService.deleteJob(id).then(
            resp => {
              console.log(resp);
              this.dataSource = null

              this.getAllVacancies()
            }
          ).catch(error => {
            console.log(error);
            this.getAllVacancies();
          }
          );
        }
      }
    )
  }

  getAllVacancies() {
    this.dataSource = null;
    this.vacancyService.getAllVacancies().then(data => {
      console.log(data);
      this.dataSource = new MatTableDataSource<any>(data.vacancy);
      this.dataSource.paginator = this.paginator;
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
