import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { VacanciesService } from 'src/app/services/vacancies/vacancies.service';
import { environment } from 'src/environments/environment';
declare var $;
@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CareersComponent implements OnInit {
  isOpened = false;
  activePage = 1;
  vacancies = [];
  loading = true;

  constructor(
    private vacancySrvc: VacanciesService,
    ) {

    this.vacancySrvc.getAllVacancies().then(
      resp => {
        let keys = [];
        resp.vacancy.forEach(element => {
          keys.push(element.descriptionFile.key);
          this.vacancies.push(
            {
              ...element,
              html: null
            }
          )
        });

        this.getOpenVacancies(keys);

      }).catch(
        error => {
          console.log(error);
        }
      )

    $(window).scroll(function () {

      var wScroll = $(window).scrollTop();

      if (wScroll >= 20) {
        $(".nav").addClass('sticky');
        $("#mobile-nav").addClass('mobile-sticky');

      } else {
        $(".nav").removeClass('sticky');
        $("#mobile-nav").removeClass('mobile-sticky');
      }

    });
    window.scrollTo({
      top: 0,
    });

  }

  ngOnInit() {

  }

  getOpenVacancies(keys: any[]) {
    this.vacancySrvc.getHtmlVacancies(keys).then(resp => {

      let vacancies = resp.map(vacancy => atob(vacancy));
      for (let index = 0; index < this.vacancies.length; index++) {
        this.vacancies[index].html = vacancies[index]

      }

      this.vacancies = this.vacancies.filter((a) => { return a.status === 'OPEN' })

      this.loading = false;
      //console.log(this.vacancies);

    }).catch(
      error => {
        this.loading = false;
        console.log(error);
      }
    );
  }



  scroll(el: HTMLElement, val) {

    window.scrollTo({
      top: el.offsetTop - 120,
      behavior: "smooth"
    });


    this.activePage = val;
  }

  open() {
    if (this.isOpened) {
      this.isOpened = false
    } else {
      this.isOpened = true;
    }
  }

}
