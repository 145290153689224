import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { environment } from '../../../environments/environment';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { EmailService } from '../../services/email/email.service'
import { FaqsService } from '../../services/faqs/faqs.service'
import { StatsService } from 'src/app/services/stats/stats.service';
import { MilestonesService } from 'src/app/services/milestones/milestones.service';
import { MediaService } from 'src/app/services/media/media.service';
import { MatSnackBar } from '@angular/material';
import { MediaCoverageService } from 'src/app/services/mediaCoverage/media-coverage.service';
import { Router } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  faqs = [];
  fileUploaded;
  mediaReleaseArr = [];

  faqIndex = -1;
  activePage = 1;
  isOpened = false;
  emailStatus = "Send";
  pageNumber;
  stats;
  milestones;
  mediacoverage;

  constructor(
    private _snackBar: MatSnackBar,
    private formBldr: FormBuilder,
    private emailService: EmailService,
    private faqSrvc: FaqsService,
    private statsSrvc: StatsService,
    private milestonesSrvc: MilestonesService,
    private mediaService: MediaService,
    private coverageSrvc : MediaCoverageService,
    private router : Router
    ) { }

  queryForm: FormGroup;


  ngOnInit() {


    this.GetAllMediaRelease();
    this.getMediaCoverage();

    this.faqSrvc.getAllFaqs().then(
      resp => {
        resp.faqs.forEach(element => {
          let answer = element.answer.split('#');
        
          this.faqs.push(
            {
              questions: element.question,
              answer: answer,
              list: element.list ? element.list.items : null
            }
          );
        });
      }
    );

    this.statsSrvc.getAllStatistics().then(
      resp => {
        resp.stats.reverse();
        if( resp.stats.length <= 5){
          this.stats = resp.stats;
        }else{
          this.stats = resp.stats.slice(0, 5);
        }
        
      }
    );

    this.milestonesSrvc.getAllMilestones().then(
      resp => {
        this.milestones = resp.milestones
      }
    )
    $(window).scroll(function () {

      var wScroll = $(window).scrollTop();

      if (wScroll >= 20) {
        $(".nav").addClass('sticky');
        $("#mobile-nav").addClass('mobile-sticky');

      } else {
        $(".nav").removeClass('sticky');
        $("#mobile-nav").removeClass('mobile-sticky');
      }

    });
    window.scrollTo({
      top: 0,
    });

    //email form
    this.queryForm = this.formBldr.group({

      fullnames: [
        null,
        [
          Validators.required,
        ]
      ],
      email: [
        null,
        [
          Validators.required,
          Validators.email
        ]
      ],
      message: [
        null,
        [
          Validators.required,
        ]
      ],
      subject: null

    });
  }

  open() {
    if (this.isOpened) {
      this.isOpened = false
    } else {
      this.isOpened = true;
    }
  }

  updatePage(val) {
    console.log(val);
    this.activePage = val
  }

  expand(i) {

    if (i == this.faqIndex) {
      this.faqIndex = -1;
    } else {
      this.faqIndex = i;
    }

  }

  scroll(el: HTMLElement, val) {

    window.scrollTo({
      top: el.offsetTop - 100,
      behavior: "smooth"
    });

    this.activePage = val;
  }

  sendEmail(formDirective) {
    if (this.queryForm.get('subject').value != null) {
      return
    }

    this.emailStatus = "Sending";
    let email = {
      replyTo: this.queryForm.value.email,
      name: this.queryForm.value.fullnames,
      subject: "Inquiry",
      message: this.queryForm.value.message
    };


    this.emailService.sendEmail(email).then(data => {
      formDirective.resetForm();
      this.queryForm.reset();
      this.emailStatus = "Sent successful";
    }).catch(error =>{
      formDirective.resetForm();
      this.queryForm.reset();
      this.emailStatus = "Failed. try again later";
    });
  }

  GetAllMediaRelease() {
    this.mediaService.getAllMediaRelease().then(media => {
      this.mediaReleaseArr = media['mediaRelease'].reverse();      
    });
  }

  getMediaCoverage(){
    this.coverageSrvc.getAllMediaCoverages().then(
      resp => {
       // console.log(resp);
        this.mediacoverage = resp.mediacoverage;
      }
    )
  }

  download(file) {
   
    this.openSnackBar(`${file.fileName}.${file.extension} will start downloading soon`, "close");
    this.mediaService.getUploadedFile(file.key).then(
      resp => {
        if (file.extension == "pdf") {
          const link = document.createElement('a');
          link.download = `${file.fileName}.${file.extension}`;
          link.href = 'data:application/octet-stream;base64,' + resp;
          link.click();
        } else {
          const link = document.createElement('a');
          link.href = 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,' + resp;
          link.download = `${file.fileName}.${file.extension}`;
          link.click();
        }
      }
    ).catch(
    );
  }


  view(media){
    this.router.navigate(['releases', {id: media.title}], { state: { data: media } })
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
    });
  }

}
