import { Component, OnInit, ViewChild } from '@angular/core';
import { MediaCoverageService } from 'src/app/services/mediaCoverage/media-coverage.service';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { MediaCoverageEditComponent } from '../../modals/media-coverage-edit/media-coverage-edit.component';
import { ConfirmationComponent } from '../../modals/confirmation/confirmation.component';

@Component({
  selector: 'app-media-coverage',
  templateUrl: './media-coverage.component.html',
  styleUrls: ['./media-coverage.component.scss']
})
export class MediaCoverageComponent implements OnInit {

  displayedColumns: string[] = ['title', 'description', 'url', 'actions'];
  dataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(private dialog: MatDialog, private mediaCoverage: MediaCoverageService) {
    this.getCoverages();
  }

  ngOnInit() {

  }

  add() {
    this.dialog.open(MediaCoverageEditComponent, { autoFocus: false, }).afterClosed().subscribe(resp => {
      this.getCoverages();
    });
  }

  edit(element) {
    this.dialog.open(MediaCoverageEditComponent, { autoFocus: false, data: element }).afterClosed().subscribe(resp => {
      this.getCoverages();
    });
  }

  delete(id) {
    let msg = "Are you sure you want to delete this Media Coverage?";
    console.log(id);
    this.dialog.open(ConfirmationComponent, { data: msg }).afterClosed().subscribe(
      resp => {
        if (resp) {
          console.log(id);
          this.dataSource = null;
          this.mediaCoverage.deleteCoverage(id).then(
            resp => {
              console.log(resp);
              this.dataSource = null
              this.getCoverages()
            }
          ).catch(error => {
            console.log(error);
            this.getCoverages();
          }
          );
        }
      }
    )
  }


  getCoverages() {
    this.dataSource = null;
    this.mediaCoverage.getAllMediaCoverages().then(data => {
      console.log(data);

      this.dataSource = new MatTableDataSource<any>(data.mediacoverage);
      this.dataSource.paginator = this.paginator;
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
