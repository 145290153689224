import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatTableDataSource, MatPaginator } from '@angular/material';
import { StatsEditComponent } from '../../modals/stats-edit/stats-edit.component';
import { StatsService } from '../../../services/stats/stats.service'
import { ConfirmationComponent } from '../../modals/confirmation/confirmation.component';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {
  displayedColumns: string[] = ['statsName', 'statsValue', 'actions'];
  dataSource;
  constructor(private dialog: MatDialog, private statsService: StatsService) { }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.getStats();
  }

  add() {
    this.dialog.open(StatsEditComponent, { autoFocus: false, }).afterClosed().subscribe(resp => {
      this.getStats();
    });
  }

  edit(element) {
    this.dialog.open(StatsEditComponent, { autoFocus: false, data: element }).afterClosed().subscribe(resp => {
      this.getStats();
    });
  }

  delete(id) {
    let msg = "Are you sure you want to delete this Statistic?";
    console.log(id);
    this.dialog.open(ConfirmationComponent, { data: msg }).afterClosed().subscribe(
      resp => {
        if (resp) {
          console.log(id);
          this.dataSource = null;
          this.statsService.deleteStat(id).then(
            resp => {
              console.log(resp);
              this.dataSource = null
              this.getStats()
            }
          ).catch(error => {
            console.log(error);
            this.getStats();
          }
          );
        }
      }
    )
  }


  getStats() {
    this.dataSource = null;
    this.statsService.getAllStatistics().then(data => {

      this.dataSource = new MatTableDataSource<any>(data.stats);
      this.dataSource.paginator = this.paginator;
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
