import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  header = new HttpHeaders({ 'enctype': 'multipart/form-data' });
  getheaders = new Headers({ 'Content-Type': 'multipart/form-data' });
  private baseUrl = environment.baseUrl;
  constructor(private http: HttpClient) { }



  getpresignedurls(fileExtension: string): Promise<any> {
    let header = new HttpHeaders({ 'Authorization': 'allow' });
    return this.http.post(`${this.baseUrl}media`, { fileExtension: fileExtension, folder: "MediaRealease" }, { headers: header }).toPromise();
  }

  uploadFile(url: string, formdata: any): Promise<any> {
    return this.http.post(url, formdata, { headers: this.header }).toPromise();
  }

  createMediaRelease(media) {
    let header = new HttpHeaders({ 'Authorization': 'allow' });
    return this.http.post(`${this.baseUrl}mediaReleases`, media, { headers: header }).toPromise();
  }

  updateMediaRelease(id,media) {
    let header = new HttpHeaders({ 'Authorization': 'allow' });
    return this.http.put(`${this.baseUrl}mediaReleases/${id}`, media, { headers: header }).toPromise();
  }


  getAllMediaRelease() {
    let header = new HttpHeaders({ 'Authorization': 'allow' });
    return this.http.get(`${this.baseUrl}mediaReleases`, { headers: header }).toPromise();
  }

  deleteMediaRelease(id){
    let header = new HttpHeaders({ 'Authorization': 'allow' });
    return this.http.delete<any>(`${this.baseUrl}mediaReleases/${id}`, { headers: header}).toPromise();
  }


  getUploadedFile(fileKey: string): Promise<any> {
    let header = new HttpHeaders({ 'Authorization': 'allow' })
    return this.http.get(`${this.baseUrl}media/`, {
      headers: header,
      params: new HttpParams().set('key', fileKey)
    }).toPromise();
  }

}
