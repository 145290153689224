import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './components/landing/landing.component';
import { AboutComponent } from './components/about/about.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { StatsComponent } from './components/dashboard/stats/stats.component';
import { VacanciesComponent } from './components/dashboard/vacancies/vacancies.component';
import { MediaComponent } from './components/dashboard/media/media.component';
import { MilestonesComponent } from './components/dashboard/milestones/milestones.component';
import { FaqsComponent } from './components/dashboard/faqs/faqs.component';
import { TiceComponent } from './components/about/tice/tice.component';
import { TutehPropComponent } from './components/about/tuteh-prop/tuteh-prop.component';
import { YabathuComponent } from './components/about/yabathu/yabathu.component';
import { LegacyComponent } from './components/about/legacy/legacy.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { CareersComponent } from './components/careers/careers.component';
import { MediaCoverageComponent } from './components/dashboard/media-coverage/media-coverage.component';
import { ReleasesComponent } from './components/releases/releases.component';
import { BlogComponent } from './components/blog/blog.component';


const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'about', component: AboutComponent },
  { path: 'education', component: TiceComponent },
  { path: 'accommodation', component: TutehPropComponent },
  { path: 'consulting', component: YabathuComponent },
  { path: 'legacy', component: LegacyComponent },
  { path: 'admin-login', component: LoginComponent },
  { path: 'careers', component: CareersComponent },
  { path: 'releases', component: ReleasesComponent },
  { path: 'careers/id', component: CareersComponent },
  { path: 'releases/id', component: ReleasesComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog/:id', component: BlogComponent },
  {
    path: 'admin', component: DashboardComponent, canActivate: [AuthGuardService],children: [
      { path: "", component: StatsComponent },
      { path: "stats", component: StatsComponent },
      { path: "vacancies", component: VacanciesComponent },
      { path: "media", component: MediaComponent },
      { path: "milestones", component: MilestonesComponent },
      { path: "faqs", component: FaqsComponent },
      { path: "coverage", component: MediaCoverageComponent },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes ,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
