import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogService } from 'src/app/services/blog/blog.service';
declare var $;
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BlogComponent implements OnInit {

  articles: any[];
  loading = true;
  activeArticle = null;
  constructor(
    private activatedRoute: ActivatedRoute,
    private blogSrvc: BlogService,
    private router: Router
  ) {


    let id;
    this.activatedRoute.params.subscribe(
      resp => {
        id = resp.id
       // console.log(resp);

        if(this.articles && id){
          this.articles.forEach(element => {
            if (element.title === id) {
              this.activeArticle = element
            }
          });
        }

      }
    );

    this.blogSrvc.getBlogsListByOrgId().then(
      resp => {
       // console.log(resp);
        this.articles = resp.blogs;
        if (id) {
          this.articles.forEach(element => {
            if (element.title === id) {
              this.activeArticle = element
            }
          });
        }

        // console.log(this.activeArticle);

        this.loading = false;
      }
    ).catch(
      error => {
       // console.log(error);

      }
    )


  }

  view(article) {
   // console.log(article);

    this.router.navigate(['blog', { id: article.title }], { state: { data: article } })
    this.activeArticle = article;
    window.scrollTo({
      top: 0,
    });
  }

  ngOnInit() {
    $(window).scroll(function () {

      var wScroll = $(window).scrollTop();

      if (wScroll >= 20) {
        $(".nav").addClass('sticky');
        $("#mobile-nav").addClass('mobile-sticky');

      } else {
        $(".nav").removeClass('sticky');
        $("#mobile-nav").removeClass('mobile-sticky');
      }

    });
    window.scrollTo({
      top: 0,
    });
  }

}
