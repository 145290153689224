import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { FaqsService } from '../../../services/faqs/faqs.service'
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-faqs-edit',
  templateUrl: './faqs-edit.component.html',
  styleUrls: ['./faqs-edit.component.scss']
})
export class FaqsEditComponent implements OnInit {

  FaqsDetailsForm: FormGroup
  listItems = [];
  listItem;
  faq = null;
  mode = "Add";
  loader;
  constructor(private faqsService: FaqsService, private formBldr: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: MatDialog) { }

  ngOnInit() {

    this.faq = this.data;
    console.log(this.faq);
    let listHeadingValidations;

    if(this.faq != null){

      listHeadingValidations = this.faq.list != null ? [Validators.required,  Validators.maxLength(255) ] : [ Validators.maxLength(255)];

    }else{
      listHeadingValidations =  [ Validators.maxLength(255)];
    }

    console.log(listHeadingValidations);
    
    this.FaqsDetailsForm = this.formBldr.group({
      Question: [
        null, [
          Validators.required,
          Validators.maxLength(255),
          Validators.minLength(2),
        
        ]
      ],
      Description: [
        null, [
          Validators.required,
          Validators.maxLength(1000),
          Validators.minLength(2),
        ]
      ],
      listHeading: [
        null, listHeadingValidations
      ]
    });

    if (this.faq != null) {
      console.log(this.faq);
      this.mode = "Edit";
      this.FaqsDetailsForm.setValue({
        Question: this.faq.question ? this.faq.question : null,
        Description: this.faq.answer ? this.faq.answer :null,
        listHeading: this.faq.list ? this.faq.list.title : null,

      });

      this.listItems = this.faq.list ? this.faq.list.items : []
    }
    
  }

  addToQueue() {
    this.listItems.push(
      this.listItem
    );
    this.listItem = null
  }

  removeItem(i) {
    this.listItems.splice(i, 1)
  }


  //create faq
  submitFaqs() {
    this.loader = "SENDING";
    
    let faq = {
      answer: this.FaqsDetailsForm.value.Description,

      list: {
        title: this.FaqsDetailsForm.value.listHeading,
        items: this.listItems
      },
      question: this.FaqsDetailsForm.value.Question,

    }
    console.log(faq);

    if (this.faq != null) {
      let id = this.faq.id;

      this.faqsService.updateFaq(id, faq).then(resp => {
        console.log(resp);
        this.loader = "DONE"
      }).catch(error => {
        console.log(error)

      });
      return
    }

    console.log(faq);

    this.faqsService.createFaq(faq).then(resp => {
      console.log(resp);
      this.loader = "DONE"
    }).catch(error => {
      console.log(error)
      // this.submitted = "failed";

    });
  }

}
