import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { VacanciesService } from '../../../services/vacancies/vacancies.service'
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ArticlesService } from 'src/app/services/articles/articles.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-vacancies-edit',
  templateUrl: './vacancies-edit.component.html',
  styleUrls: ['./vacancies-edit.component.scss'],
  providers: [DatePipe]
})
export class VacanciesEditComponent implements OnInit {

  skills = [];
  skill;
  blogPostForm: FormGroup;
  mode = "Add";
  vacancy;
  loader;
  userId = environment.userId;
  vacancyData;
  vacancyDesc;
  downloading;
  vacancyDetailsForm: FormGroup;
  constructor(
    private articleService: ArticlesService,
    private formBldr: FormBuilder, private vacancyService: VacanciesService, @Inject(MAT_DIALOG_DATA) public data: MatDialog, private datePipe: DatePipe) {

  }

  ngOnInit() {
    this.vacancy = this.data;
    // this.downloadProffessionalFile(this.vacancy);
    this.vacancyDetailsForm = this.formBldr.group({
      vacancyTittle: [null, [
        Validators.required,
        Validators.maxLength(255),
      ]
      ],
      vacancyDesc: [
        null, [
          Validators.required,
          Validators.minLength(2)
        ]
      ],
      closingDate: [
        null, [
          Validators.required,
          Validators.maxLength(10)
        ]
      ],

    });

    if (this.vacancy != null) {
      let dateSplit;
      let closingDate
      if (this.vacancy.closing_date.indexOf('/') > 0) {
        dateSplit = this.vacancy.closing_date.split("/");
        closingDate = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;

      } else {
        closingDate = this.vacancy.closing_date
      }
      // let closingDate = this.vacancyDetailsForm.value.closingDate;
      // closingDate = this.datePipe.transform(closingDate, 'yyyy-mm-dd')
      this.mode = "Edit";
      this.vacancyDetailsForm.setValue({
        vacancyTittle: this.vacancy.position,
        vacancyDesc: this.vacancy.vacancyDesc,
        closingDate: closingDate,
      });

      this.skills = this.vacancy.skills ? this.vacancy.skills : [];
    }

  }

  addToQueue() {
    this.skills.unshift(
      this.skill
    );
    this.skill = null
  }

  removeItem(i) {
    this.skills.splice(i, 1)
  }

  SubmitVacancy() {
    console.log(this.vacancyDetailsForm);
    this.submitForm();
  }

  async uploadVacancyDescFile() {
    return new Promise(async (resolve, reject) => {
      try {
        const s3Token: any = await this.vacancyService.getPresignedPost();
        const file = new Blob([this.vacancyDetailsForm.value.vacancyDesc], { type: 'plain/html' });

        let form = new FormData();

        Object.keys(s3Token.presignedPost.fields).forEach(key => form.append(key, s3Token.presignedPost.fields[key]));
        form.append('file', file);

        await this.vacancyService.uploadArticleFile(s3Token.presignedPost.url, form).then(
          resp => {
            console.log(resp);

          }
        );

        this.vacancyData['descriptionFile'] = {
          "key": s3Token.id,
          "fileName": "vacancy",
          "extension": "html"
        };

        resolve(null);

      } catch (err) {
        console.log('==uploadArticleFile error==', err);
        reject(err);
      }
    });
  }

  async createVacancy() {
    console.log(this.vacancyData);

    try {

      let resp = await this.vacancyService.createVacancy(this.vacancyData);
      console.log(resp);
      this.loader = 'DONE'
      return resp;
    } catch (err) {
      console.log('==error==', err)
    }

  }
  async editVacancy() {
    console.log(this.vacancyData);

    try {
      this.vacancyData['status'] = this.vacancy.status;
      let resp = await this.vacancyService.updateJob(this.vacancy.id, this.vacancyData);
      console.log(resp);
      this.loader = 'DONE'

      return resp;
    } catch (err) {
      console.log('==error==', err)
    }

  }


  async submitForm() {
    //this.loading();
    this.loader = 'SENDING'
    let closingDate = this.vacancyDetailsForm.value.closingDate;
    closingDate = this.datePipe.transform(closingDate, "dd/MM/yyyy")
    this.vacancyData = {
      user: { id: this.userId },
      position: this.vacancyDetailsForm.value.vacancyTittle,
      closing_date: closingDate,
      skills: this.skills,
    };

    console.log(this.vacancyData);


    await this.uploadVacancyDescFile();
    if (this.mode == "Edit") {
      const editVacancy = await this.editVacancy();
      console.log('==The form has been submitted==', editVacancy);
    } else {
      const createdArticle = await this.createVacancy();
      console.log('==The form has been submitted==', createdArticle);

    }
    // this.openSnackBar("Article Published successfully");
    // this.clearDraft();
    // this.setStep(0)
    return;

  }
  downloadProffessionalFile(file) {

    file = file.descriptionFile;
    this.downloading = true;
    this.vacancyService.getUploadedVacancyDesc(file.key).then(resp => {
      this.vacancyDesc = window.atob(resp);
    }).catch(error => {
      console.log(error);
      this.downloading = false;
    })
  }

}
