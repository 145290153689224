import { Component, OnInit, ViewChild } from '@angular/core';
import { MilestonesEditComponent } from '../../modals/milestones-edit/milestones-edit.component';
import { MatDialog, MatTableDataSource, MatPaginator } from '@angular/material';
import {MilestonesService} from '../../../services/milestones/milestones.service'
import { ConfirmationComponent } from '../../modals/confirmation/confirmation.component';

@Component({
  selector: 'app-milestones',
  templateUrl: './milestones.component.html',
  styleUrls: ['./milestones.component.scss']
})
export class MilestonesComponent implements OnInit {
  displayedColumns: string[] = ['date', 'milestone', 'actions'];
  dataSource;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  
  constructor(private dialog: MatDialog, private milestoneService: MilestonesService) { }

  ngOnInit() {
  this.getAllMilestones();
  }

  add(){
    this.dialog.open(MilestonesEditComponent, { autoFocus: false,}).afterClosed().subscribe(resp => {
      this.getAllMilestones();
    });
  }

  edit(element){
    this.dialog.open(MilestonesEditComponent, { autoFocus: false, data : element}).afterClosed().subscribe(resp => {
      this.getAllMilestones();
    });
  }

  delete(id){
    let msg = "Are you sure you want to delete this Milestone";
    console.log(id);
    
    this.dialog.open(ConfirmationComponent, {data : msg }).afterClosed().subscribe(
      resp => {
        if(resp) {
          this.dataSource = null;
          this.milestoneService.deleteMilestone(id).then(
           resp => {
            this.getAllMilestones()
           }
          ).catch(error => {
            console.log(error)
            this.getAllMilestones()
          }
          );
        }
      }
    )
  }

  getAllMilestones(){
    this.dataSource = null;
    this.milestoneService.getAllMilestones().then(data =>{
      console.log('my milestones', data);
      this.dataSource = new MatTableDataSource<any>(data.milestones);
      this.dataSource.paginator = this.paginator;
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
