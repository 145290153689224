import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-tuteh-prop',
  templateUrl: './tuteh-prop.component.html',
  styleUrls: ['./tuteh-prop.component.scss']
})
export class TutehPropComponent implements OnInit {
  isOpened = false;
  activePage = 1;
  constructor() {
    $(window).scroll(function () {

      var wScroll = $(window).scrollTop();

      if (wScroll >= 20) {
        $(".nav").addClass('sticky');
        $("#mobile-nav").addClass('mobile-sticky');

      } else {
        $(".nav").removeClass('sticky');
        $("#mobile-nav").removeClass('mobile-sticky');
      }

    });
    window.scrollTo({
      top:0,
    });
   }

  ngOnInit() {
  }

  scroll(el: HTMLElement, val) {

    window.scrollTo({
      top: el.offsetTop - 120,
      behavior: "smooth"
    });


    this.activePage = val;
  }

  open() {
    if (this.isOpened) {
      this.isOpened = false
    } else {
      this.isOpened = true;
    }
  }

}
