import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog'
@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  message ;
  constructor(@Inject(MAT_DIALOG_DATA) public data: MatDialog) { 
    this.message =data
    
  }

  ngOnInit() {
  }

}
