import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FaqsService {
  private baseUrl = environment.baseUrl;
  headers = new HttpHeaders({ 'Authorization': 'allow' });

  constructor(private http: HttpClient) { }


  getAllFaqs(): Promise<any> {

    return this.http.get<any>(`${this.baseUrl}faqs`, { headers: this.headers }).toPromise();
  }

  createFaq(faq) {
    return this.http.post<any>(`${this.baseUrl}faqs`, faq, { headers: this.headers }).toPromise();
  }

  updateFaq(id, faq){
    return this.http.put<any>(`${this.baseUrl}faqs/${id}`, faq, { headers: this.headers }).toPromise();
  }

  deleteFaq(id){
    return this.http.delete<any>(`${this.baseUrl}faqs/${id}`, { headers: this.headers }).toPromise();
  }

  nameValidator(control: FormControl): { [s: string]: boolean } {
    const nameRegex = /^[a-zA-Z]+(([' -][a-zA-Z ])?[a-zA-Z]*)*$/;

    if (!nameRegex.test(control.value) && control.value) {
      return { nameValidator: true };
    }
    return null;
  }

  
}
