import { Component, OnInit, ViewChild } from '@angular/core';
import { FaqsEditComponent } from '../../modals/faqs-edit/faqs-edit.component';
import { MatDialog, MatTableDataSource, MatPaginator } from '@angular/material';
import {FaqsService} from '../../../services/faqs/faqs.service'
import { ConfirmationComponent } from '../../modals/confirmation/confirmation.component';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = ['question', 'answer', 'actions' ];
  dataSource ;
  
  constructor(private dialog: MatDialog, private faqService: FaqsService) { }

  ngOnInit() {
    this.getAllFaqs();
  }

  
  add(){
    this.dialog.open(FaqsEditComponent, { autoFocus: false,}).afterClosed().subscribe(resp => {
      this.getAllFaqs();
    });
  }

  edit(element){
    this.dialog.open(FaqsEditComponent, { autoFocus: false,data : element}).afterClosed().subscribe(resp => {
      this.getAllFaqs();
    });
  }

  delete(id){
    let msg = "Are you sure you want to delete this FAQ";
    console.log(id);
    
    this.dialog.open(ConfirmationComponent, {data : msg }).afterClosed().subscribe(
      resp => {
        if(resp) {
          this.dataSource = null;
          this.faqService.deleteFaq(id).then(
           resp => {
            this.getAllFaqs()
           }
          ).catch(error => {this.getAllFaqs();console.log(error)}
          );
        }
      }
    )
  }

  getAllFaqs(){
    this.dataSource = null;
    this.faqService.getAllFaqs().then(data =>{
      console.log('my Faqs', data);
      this.dataSource = new MatTableDataSource<any>(data.faqs);
      this.dataSource.paginator = this.paginator;
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
