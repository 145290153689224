import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MediaCoverageService } from 'src/app/services/mediaCoverage/media-coverage.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-media-coverage-edit',
  templateUrl: './media-coverage-edit.component.html',
  styleUrls: ['./media-coverage-edit.component.scss']
})
export class MediaCoverageEditComponent implements OnInit {

  coverage;
  CoverageDetailsForm: FormGroup
  loader
  mode = "Add";
  constructor(@Inject(MAT_DIALOG_DATA) public data: MatDialog, private formBldr: FormBuilder, private coverageService: MediaCoverageService) { }

  ngOnInit() {
    this.coverage  = this.data;
    console.log( this.coverage);
    

    this.CoverageDetailsForm = this.formBldr.group({

      title: [
        null, [
          Validators.required,
          Validators.maxLength(255),
          Validators.minLength(1),
        ]
      ],
      description: [
        null, [
          Validators.required,
          Validators.maxLength(255),
          Validators.minLength(1),
        ]
      ],
      url: [
        null, [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1000)
        ]
      ],
    });

    if (this.coverage != null) {
      this.mode = "Edit";
      this.CoverageDetailsForm.setValue({
        url: this.coverage.mediaUrl,
        title: this.coverage.title,
        description: this.coverage.description,
      });
    }

  }
  submitCoverage() {
    this.loader = "SENDING";

    //change to "DONE" inside the promise
    let coverage = {
      mediaUrl: this.CoverageDetailsForm.value.url,
      description: this.CoverageDetailsForm.value.description,
      title : this.CoverageDetailsForm.value.title
    };
    console.log(coverage);

    if (this.coverage != null) {
      let id = this.coverage.id
      this.coverageService.updateMediaCoverages(id, coverage).then(resp => {
        this.loader = "DONE";
      }).catch(error => {
        console.log(error)
      });

      return;
    }else{
      this.coverageService.createMediaCoverages(coverage).then(resp => {
        this.loader = "DONE"
      }).catch(error => {
        console.log(error)
      });
    }
  }

}
