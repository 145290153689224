import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ConfirmationComponent } from '../modals/confirmation/confirmation.component';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  loading = false;
  loadingStatus = "Getting things ready...";
  constructor(private router: Router,private dialog: MatDialog,private authSrvc : AuthService ) { }

  opened = false;
  hasBackdrop = false;
  mode = "side";
  page = "stats";



  ngOnInit() {

    if (window.innerWidth < 680) {   
      this.hasBackdrop = true;
      this.mode =  "push";
    }else{
      this.opened = true;
    }

    console.log(this.page);
    

    let temp = location.hash.split("/");
    console.log(temp);
    
    this.page = temp[2] ? temp[2] : 'stats';

  }

  changepage(param) {
    //console.log(param);
    let temp = this.router.url.split("/");
    this.page = temp[2] == null ? 'overview' : temp[2];

    this.page = temp[2] == "apply" ? 'myroom' : temp[2];
    this.page = temp[1] == 'dashboard' ? 'overview' : temp[1];
    this.page = param;

  }

  signOut() {
    const signOutDialog = this.dialog.open(ConfirmationComponent, {
      data: "Are you sure you want to sign out?"
    });

    signOutDialog.afterClosed().subscribe(resp => {
      //console.log(resp);

      if (resp) {
        this.authSrvc.signout().then(
          resp => {
            this.router.navigate(['/admin-login']);
          }
        );
      }
    });
  }

}
