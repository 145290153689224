import { Component, OnInit, Inject } from '@angular/core';
import { VacanciesService } from '../../../services/vacancies/vacancies.service'
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-view-vacancy-desc',
  templateUrl: './view-vacancy-desc.component.html',
  styleUrls: ['./view-vacancy-desc.component.scss']
})
export class ViewVacancyDescComponent implements OnInit {

  vacancy
  vacancyDesc
  
  constructor(private vacancyService: VacanciesService, @Inject(MAT_DIALOG_DATA) public data: MatDialog) {
    this.downloadProffessionalFile(this.data)
   }

  ngOnInit() {
    this.vacancy = this.data;
    console.log(this.vacancy);
    // this.downloadProffessionalFile(this.vacancy)
  }
  downloading = false;

  downloadProffessionalFile(file) {
    file = file.descriptionFile;
    console.log(file);

    console.log(file);
    this.downloading = true;
    this.vacancyService.getUploadedVacancyDesc(file.key).then(resp => {
      this.vacancyDesc = window.atob(resp);
      document.getElementById("vacancyDescription").innerHTML = this.vacancyDesc;

    }).catch(error => {
      console.log(error);
      this.downloading = false;
    })
  }
}
